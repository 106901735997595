import React from "react";
import {
  makeBannerProps,
  makeHtml,
  makeStandardPageProps,
  maxImgWidth,
  readContentValues,
  StandardPageProps,
} from "../../utils/common";
import BannerComponent from "./../mapped-templates/BannerTemplate";
import HeaderComponentTemplate from "./../mapped-templates/HeaderTemplate";
import { Container, Grid, Hidden, Typography } from "@mui/material";
import { BlogArticle, BlogHub } from "../../components/SemanticTheme/BlogHub";
import { BannerWidgetContainer } from "./../mapped-templates/BannerWidgetContainerTemplate";
import PageProperties from "./../PagePropertiesTemplate";
import {
  assignFeefoReviewAPITag,
  checkTradingTabExpiry,
  OnMobileDevice,
} from "../../utils";
import FooterComponentTemplate from "./../mapped-templates/FooterTemplate";
import PrimaryContent from "../../utils/PrimaryContent";
import ContentArea from "../../utils/ContentArea";
import { BlogArticle_S as blogArticles } from "@generated/types";
import {
  updateAbsoluteLink,
  useGenerateResponsiveImageUrl,
} from "src/components/Utils";
import { MobileTradingTab } from "@components/SemanticTheme/MobileTradingTab";
import {
  BasicPageBannerComponentDynamicZone,
  BlogHubPageEntityResponseCollection,
  FooterEntityResponseCollection,
  HeaderEntityResponseCollection,
  SeoStructuredDataEntityResponse,
} from "src/graphql/generated-strapi/types";
import { ParsedUrlQuery } from "querystring";

interface BlogHubPageProps {
  rest: {
    strapiContent: {
      data: { blogHubPages: BlogHubPageEntityResponseCollection };
    };
    headerContent: {
      data: { headers: HeaderEntityResponseCollection };
    };
    footerContent: {
      data: { footers: FooterEntityResponseCollection };
    };
    seoStructuredData: {
      data: {
        seoStructuredData: SeoStructuredDataEntityResponse;
      };
    };
    bslData: {
      blogArticles: blogArticles[];
    };
    experiments?: { [key in string]: string | boolean };
    hideSubscribeSticky?: boolean;
    queryData?: ParsedUrlQuery;
    headers?: any;
  };
}

type CmsData = ReturnType<typeof makeCmsData>;
const makeCmsData = (content: any) => ({
  ...readContentValues(content, [
    "ArticleListLabel",
    "ArticleLoadCount",
    "LoadMoreButtonLabel",
    "LoadMoreButtonTheme",
    "NoResultsMessage",
    "BlogsPerRow",
  ]),
  blogCategory: content?.["BlogCategory"]?.data?.attributes?.Name ?? undefined,
});

const BlogHubPage = (props: BlogHubPageProps) => {
  const experiments = props?.rest?.experiments;
  //CMS Data
  const seoStructuredData =
    props?.rest?.seoStructuredData?.data?.seoStructuredData?.data?.attributes;
  const blogHubPageCmsData =
    props?.rest?.strapiContent?.data?.blogHubPages?.data?.[0]?.attributes;
  const pageImgQuality = blogHubPageCmsData?.ImageQuality;
  const articles = props?.rest?.bslData?.blogArticles;
  const cmsData: CmsData = blogHubPageCmsData
    ? makeCmsData(blogHubPageCmsData)
    : null;
  const bannerProps = makeBannerProps(blogHubPageCmsData || {});
  const tradingTabProps = blogHubPageCmsData?.TradingTab?.data?.attributes;
  const bannerComponentRef =
    blogHubPageCmsData?.BannerComponent as BasicPageBannerComponentDynamicZone[];
  const isBookingWidget =
    bannerComponentRef && !!Object.keys(bannerComponentRef)?.length;
  const pageProperties: StandardPageProps = makeStandardPageProps(
    blogHubPageCmsData?.seo || {},
  );
  assignFeefoReviewAPITag(pageProperties, blogHubPageCmsData);
  pageProperties["ShowSubscribeModal"] = blogHubPageCmsData?.ShowSubscribeModal;
  const headerProps =
    props?.rest?.headerContent?.data?.headers?.data?.[0]?.attributes;
  const footerProps =
    props?.rest?.footerContent?.data?.footers?.data?.[0]?.attributes;
  const stickyBannerProps =
    blogHubPageCmsData?.StickyBanner?.data?.attributes || null;
  const primaryContent = blogHubPageCmsData?.PrimaryContent;

  if (stickyBannerProps && headerProps)
    headerProps["stickyBannerProps"] = stickyBannerProps;
  if (pageImgQuality && headerProps)
    headerProps["pageImgQuality"] = pageImgQuality;
  if (props?.rest?.headers) pageProperties["nextHeaders"] = props.rest.headers;
  if (!!seoStructuredData)
    pageProperties["seoStructuredData"] = seoStructuredData;

  const blogImageSizes = { xs: 300, sm: 300, md: 300, lg: 300, xl: 300 };

  const generateImageUrl = (
    url: string | undefined,
    maxWidths?: maxImgWidth,
    imgQuality: number = blogHubPageCmsData?.ImageQuality,
  ) => {
    if (url) {
      return useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true);
    }
  };
  const parsedText = (text?: string) => {
    return text ? (
      <div dangerouslySetInnerHTML={makeHtml(text as string)} />
    ) : undefined;
  };
  const generateResizedImages = (articles: blogArticles[]) => {
    return articles?.map((article: blogArticles) => {
      return {
        ...article,
        imageURL: !!article?.imageURL
          ? generateImageUrl(article?.imageURL, blogImageSizes)
          : "",
        blogURL: article?.url,
        categoryName: article?.category?.name,
        categoryColour: article?.category?.colour?.value,
      };
    });
  };

  let nextHeaders: any;
  if (!!props?.rest?.headers) {
    nextHeaders = JSON.parse(props?.rest?.headers);
  }
  const isMobileDevice = OnMobileDevice(nextHeaders);

  const bannerImgURL = isMobileDevice
    ? bannerProps?.mobileBannerImage || bannerProps?.bannerImage
    : bannerProps?.bannerImage;

  const mobileYellowBannerDeskandTab =
    !!experiments?.mobileYellowBannerDeskandTab;
  const moveSearchAndBannerBelowHero =
    !!experiments?.moveSearchAndBannerBelowHero;

  return (
    <>
      <PageProperties {...pageProperties} />
      {bannerProps && (
        <BannerComponent
          {...bannerProps}
          isBookingWidget={isBookingWidget}
          queryData={props?.rest?.queryData}
          bannerImageURL={bannerImgURL}
          additionalProps={experiments}
        >
          {headerProps && (
            <HeaderComponentTemplate
              content={headerProps}
              additionalProps={experiments}
              hideSubscribeSticky={props?.rest?.hideSubscribeSticky}
            />
          )}
          <Hidden mdDown={moveSearchAndBannerBelowHero}>
            {tradingTabProps?.TradingTabInMobile &&
              checkTradingTabExpiry(bannerProps) && (
                <MobileTradingTab
                  tradingTab={{
                    url: updateAbsoluteLink(
                      bannerProps?.tradingTabURL?.data?.attributes?.PageRoute,
                    ),
                    tradingTabInMobile: parsedText(
                      tradingTabProps?.TradingTabInMobile,
                    ),
                  }}
                  isBookingWidget={isBookingWidget}
                  mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
                />
              )}
            {bannerComponentRef && isBookingWidget && (
              <BannerWidgetContainer
                mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
              >
                <ContentArea
                  content={bannerComponentRef}
                  pageImgQuality={pageImgQuality}
                  additionalProps={experiments}
                />
              </BannerWidgetContainer>
            )}
          </Hidden>
        </BannerComponent>
      )}
      {moveSearchAndBannerBelowHero && (
        <Hidden mdUp>
          {tradingTabProps?.TradingTabInMobile &&
            checkTradingTabExpiry(bannerProps) && (
              <MobileTradingTab
                tradingTab={{
                  url: updateAbsoluteLink(
                    bannerProps?.tradingTabURL?.data?.attributes?.PageRoute,
                  ),
                  tradingTabInMobile: parsedText(
                    tradingTabProps?.TradingTabInMobile,
                  ),
                }}
                isBookingWidget={isBookingWidget}
                mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
              />
            )}
          {bannerComponentRef && isBookingWidget && (
            <BannerWidgetContainer
              mobileYellowBannerDeskandTab={mobileYellowBannerDeskandTab}
            >
              <ContentArea
                content={bannerComponentRef}
                pageImgQuality={pageImgQuality}
                additionalProps={experiments}
              />
            </BannerWidgetContainer>
          )}
        </Hidden>
      )}
      <Container style={{ marginTop: 20 }}>
        <Typography variant="h2">{cmsData?.ArticleListLabel}</Typography>
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <BlogHub
                articleLoadCount={articles?.length}
                articles={generateResizedImages(articles) as BlogArticle[]}
                noResultsMessage={cmsData?.NoResultsMessage}
                blogsPerRow={
                  cmsData?.BlogsPerRow ? +cmsData?.BlogsPerRow : undefined
                }
              />
            </Grid>
          </Grid>
        </>
        <Grid item xs={12}>
          {primaryContent && (
            <PrimaryContent
              primaryContent={primaryContent}
              pageImgQuality={pageImgQuality}
              additionalProps={experiments}
            />
          )}
        </Grid>
      </Container>

      {footerProps && (
        <FooterComponentTemplate
          content={{
            ...footerProps,
            experiments,
          }}
        />
      )}
    </>
  );
};

export default BlogHubPage;
